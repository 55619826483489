import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'index',
  component: () => import('../views/index.vue')
},
{
  path: '/user',
  name: 'user',
  component: () => import('../views/user.vue')

},

{
  path: '/vehicle',
  name: 'vehicle',
  component: () => import('../views/vehicle.vue')

},
{
  path: '/logistics',
  name: 'logistics',
  component: () => import('../views/logistics.vue')

},
{
  path: '/finance',
  name: 'finance',
  component: () => import('../views/finance.vue')
},
{
  path: '/supply',
  name: 'supply',
  component: () => import('../views/supply.vue')
},
{
  path: '/smart',
  name: 'smart',
  component: () => import('../views/smart.vue')
},
]
const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router